import React, { useCallback, useMemo } from 'react';
import { Link, matchPath, useHistory } from 'react-router-dom';

import { globalState } from '../../../../shared/foreground/models';
import combineClasses from '../../../../shared/foreground/utils/combineClasses';
import { DisplayTheme } from '../../../../shared/types';
import HelpDropdown from '../Dropdown/HelpDropdown';
import { ShowNavigationLeftPanelButton } from '../ShowNavigationLeftPanelButton';
import StandardPage from '../StandardPage';
import standardPageStyles from '../StandardPage.module.css';
import styles from './AccountSettingsPage.module.css';

function NavLink({
  children: navLinkTitle,
  to,
}: {
  children: string;
  to: string;
}) {
  const {
    location: { pathname },
  } = useHistory();
  const isActive = useMemo(() => matchPath(pathname, to), [pathname, to]);
  const isPathnameExactlyTheSame = useMemo(() => pathname === to, [pathname, to]);
  const classes: string[] = [];

  if (isActive) {
    classes.push(styles.navLinkActive);
    if (isPathnameExactlyTheSame) {
      classes.push(styles.navLinkExactlyActive);
    }
  }

  return (
    <Link className={combineClasses(classes)} to={to}>
      {navLinkTitle}
    </Link>
  );
}

export function AccountSettingsPage({
  additionalHeaderContent,
  children,
  subtitle,
  title,
}: {
  additionalHeaderContent?: React.ReactNode;
  children: React.ReactNode;
  subtitle?: string;
  title: string;
}) {
  const isDarkMode = globalState(
    useCallback((state) => state.webEffectiveTheme === DisplayTheme.Dark, []),
  );

  let contentHeaders: JSX.Element | undefined;
  if (subtitle) {
    contentHeaders = (
      <div className={styles.mainHeaderWrapper}>
        <h1 className={styles.mainHeader}>{title}</h1>
        <p className={styles.subtitle}>{subtitle}</p>
      </div>
    );
  }

  return (
    <StandardPage>
      <div
        className={`${standardPageStyles.standardPageWrapper} ${styles.wrapper} ${
          isDarkMode ? styles.isDarkMode : ''
        }`}
      >
        <div className={standardPageStyles.header}>
          <ShowNavigationLeftPanelButton />
          <h1 className={standardPageStyles.title}>{title}</h1>
          {additionalHeaderContent}
        </div>

        <div
          className={`${standardPageStyles.contentMainWrapper} ${styles.contentMainWrapper} has-visible-scrollbar`}
        >
          <div className={standardPageStyles.contentWrapper}>
            <aside>
              <nav>
                <NavLink to="/profile">Profile</NavLink>
                <NavLink to="/preferences">Preferences</NavLink>
                <NavLink to="/integrations">Integrations</NavLink>
                <NavLink to="/product-emails">Product Emails</NavLink>
                <div>
                  <hr />
                </div>
                <NavLink to="/add-to-library">Add to Library</NavLink>
                <NavLink to="/add-to-feed">Add to Feed</NavLink>
                <NavLink to="/resources">Resources</NavLink>
              </nav>
            </aside>

            <div className={styles.content}>
              {contentHeaders}
              {children}

              <div className={styles.helpDropdownWrapper}>
                <HelpDropdown />
              </div>
            </div>
          </div>
        </div>
      </div>
    </StandardPage>
  );
}

export function List({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <div className={combineClasses(styles.list, className)}>{children}</div>;
}

export function ListItem({
  children,
  className,
  image,
  isSmall,
  subtitle,
  title,
}: {
  children: React.ReactNode;
  className?: string;
  image?: JSX.Element;
  isSmall?: boolean;
  subtitle: string | React.ReactNode;
  title: string;
}) {
  let imageWrapper: JSX.Element | undefined;
  if (image) {
    imageWrapper = <div className={styles.imageWrapper}>{image}</div>;
  }

  return (
    <div className={combineClasses(styles.listItem, isSmall ? styles.isSmall : '', className)}>
      <div className={styles.left}>
        {imageWrapper}
        <div className={styles.textWrapper}>
          <div className={styles.title}>
            <h1>{title}</h1>
          </div>
          <div className={styles.subtitle}>{subtitle}</div>
        </div>
      </div>

      <div className={styles.right}>{children}</div>
    </div>
  );
}

export function Section({
  children,
  contentBoxClassName,
  id,
  title,
  titleClassName,
}: {
  children: React.ReactNode;
  contentBoxClassName?: string;
  id: string;
  title: string;
  titleClassName?: string;
}) {
  return (
    <section id={id} className={styles.section}>
      <div className={styles.titleWrapper}>
        <h2 className={titleClassName}>{title}</h2>
      </div>
      <div className={combineClasses(styles.sectionContentBox, contentBoxClassName)}>{children}</div>
    </section>
  );
}
